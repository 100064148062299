import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from 'libs/environment/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SignOutGuard implements CanActivate {
  constructor(private authService: AngularFireAuth, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.user.pipe(
      map((user) => {
        if (!user) {
          this.router
            .navigateByUrl('login')
            .then(() => window.location.reload());
          return false;
        }
        if (user) {
          return this.authService
            .signOut()
            .then(() => {
              localStorage.clear();
              return this.router.navigateByUrl('login');
            })
            .then(() => {
              window.location.reload();
              return false;
            });
        } else {
          return false;
        }
      }),
      map((res) => {
        if (res) {
          return true;
        } else {
          return false;
        }
      }),
      catchError((err) => {
        if (!environment.production) {
          console.error('Error at Auth Guard => ', err);
        }
        localStorage.clear();
        this.router.navigateByUrl('login').then(() => window.location.reload());
        return of(false);
      })
    );
  }
}
